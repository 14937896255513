import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import Image from '../image';
import { Article } from '@/models/articles';

interface CardProps {
  article: Article;
}

const ArticleCard: FunctionComponent<CardProps> = ({ article }) => {
  return (
    <Link
      to={
        article.status === 'draft'
          ? `/app/article/${article.locale}/${article.slug}`
          : `/article/${article.slug}`
      }
    >
      <div className="w-full p-6 flex flex-col flex-grow flex-shrink transform hover:scale-105 transition duration-500">
        <div className="flex-1 bg-white dark:bg-deepblue rounded-xl overflow-hidden shadow-lg  ">
          <div>
            <Image
              style={{ maxHeight: '250px' }}
              media={article.image}
              alt={`Hero image from article ${article.title}`}
              className="w-full"
            />
          </div>
          <div className="p-4">
            <div className="flex flex-row gap-2">
              {article.categories.length &&
                article.categories.map((category) => (
                  <p
                    id="category"
                    key={`${article.slug}__${category.name}`}
                    className="uppercase text-xs"
                  >
                    {category.name}
                  </p>
                ))}
            </div>

            <p id="title" className="heading3">
              {article.title}
            </p>
            <p id="description">{article.description}</p>
            <div>
              <hr className="my-2" />
              <div className="flex justify-start align-center content-center gap-2">
                <div>
                  {article.author.picture && (
                    <Image
                      media={article.author.picture}
                      alt={`Picture of ${article.author.name}`}
                      style={{ borderRadius: '50%', maxHeight: '25px' }}
                    />
                  )}
                </div>
                <p className="font-medium">{article.author.name}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ArticleCard;
