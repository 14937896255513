import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { Appearance, ButtonLinkProp } from '@/utils/types';
import Loader from '../icons/loader';

type ButtonType = 'button' | 'submit' | 'reset';

interface ButtonProp {
  button: ButtonLinkProp;
  appearance: Appearance;
  compact: boolean;
  loading: boolean;
  disabled: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: ButtonType;
}

const Button: FunctionComponent<ButtonProp> = ({
  button,
  appearance,
  compact = false,
  handleClick,
  loading = false,
  disabled = false,
  type,
}) => {
  return (
    <button onClick={handleClick} type={type}>
      <div
        className={classNames(
          // Common classes
          'flex w-full justify-center lg:w-auto text-center uppercase tracking-wide font-semibold text-base md:text-sm border-2 rounded-md',
          // Full-size button
          {
            'px-8 py-4': compact === false,
          },
          // Compact button
          {
            'px-6 py-2': compact === true,
          },
          // Specific to when the button is fully dark
          {
            'bg-primary text-white border-primary': appearance === 'dark',
          },
          // Specific to when the button is dark outlines
          {
            'text-primary border-primary': appearance === 'dark-outline',
          },
          // Specific to when the button is fully white
          {
            'bg-white text-primary border-white': appearance === 'white',
          },
          // Specific to when the button is white outlines
          {
            'text-white border-white': appearance === 'white-outline',
          },
        )}
      >
        {loading && <Loader />}
        {button.text}
      </div>
    </button>
  );
};

export default Button;
